<template>
  <div v-if="visible">
    <div style="text-align: left; display: inline-block; width: 500px">
      <span style="font-weight: bold">
        {{ role.name }}
      </span>
      <a-tree
        checkable
        @expand="onExpand"
        :expandedKeys="expandedKeys"
        :autoExpandParent="false"
        v-model="checkedKeys"
        :treeData="Object.values(actionsByResource)"
        @check="onChange"
      />
    </div>
  </div>
</template>

<script>
import { buildActionsByPrefix, enabledActions } from "../../../utils/actions";

export default {
  name: "editRoleActions",

  props: ["role", "actions", "visible"],

  data() {
    return {
      actionsByResource: buildActionsByPrefix(this.actions),
      expandedKeys: [],
      checkedKeys: enabledActions(
        this.actions,
        (this.role && this.role.actions) || []
      )
    };
  },

  methods: {
    onChange(checkedKeys) {
      this.checkedKeys = checkedKeys;
      this.$emit("onActions", checkedKeys);
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
    }
  }
};
</script>

<style scoped></style>
