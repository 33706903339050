<template>
  <DescriptionList title="角色信息" size="large" v-if="visible">
    <a-card :bordered="false">
      <div class="table-operator">
        <a-form :form="form">
          <a-form-item label="角色名称">
            <a-input
              style="max-width: 300px"
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.role.name
                }
              ]"
              placeholder="请输入"
            />
          </a-form-item>
          <a-form-item label="角色说明">
            <a-input
              style="max-width: 300px"
              v-decorator="[
                'description',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.role.description
                }
              ]"
              placeholder="请输入"
            />
          </a-form-item>
          <a-form-item label="角色类型">
            <a-select
              style="max-width:80px"
              v-decorator="[
                'roleType',
                {
                  rules: [{ required: true, message: '请选择' }],
                  initialValue: this.role.roleType
                }
              ]"
              placeholder="请选择"
            >
              <a-select-option :key="`FRONT_END`">前台</a-select-option>
              <a-select-option :key="`BACK_END`">后台</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
  </DescriptionList>
</template>

<script>
import DescriptionList from "@/components/DescriptionList/DescriptionList";

export default {
  name: "editRoleDetails",
  props: ["role", "visible"],

  components: {
    DescriptionList
  },

  created() {
    this.$emit("onDetailsForm", this.form);
  },

  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: () => this.$emit("onDetailsForm", this.form),
        onFieldsChange: () => this.$emit("onDetailsForm", this.form)
      })
    };
  }
};
</script>

<style scoped></style>
