var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('DescriptionList',{attrs:{"title":"角色信息","size":"large"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"table-operator"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [{ required: true, message: '请输入' }],
                initialValue: this.role.name
              }
            ]),expression:"[\n              'name',\n              {\n                rules: [{ required: true, message: '请输入' }],\n                initialValue: this.role.name\n              }\n            ]"}],staticStyle:{"max-width":"300px"},attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"角色说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'description',
              {
                rules: [{ required: true, message: '请输入' }],
                initialValue: this.role.description
              }
            ]),expression:"[\n              'description',\n              {\n                rules: [{ required: true, message: '请输入' }],\n                initialValue: this.role.description\n              }\n            ]"}],staticStyle:{"max-width":"300px"},attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"角色类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'roleType',
              {
                rules: [{ required: true, message: '请选择' }],
                initialValue: this.role.roleType
              }
            ]),expression:"[\n              'roleType',\n              {\n                rules: [{ required: true, message: '请选择' }],\n                initialValue: this.role.roleType\n              }\n            ]"}],staticStyle:{"max-width":"80px"},attrs:{"placeholder":"请选择"}},[_c('a-select-option',{key:`FRONT_END`},[_vm._v("前台")]),_c('a-select-option',{key:`BACK_END`},[_vm._v("后台")])],1)],1)],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }