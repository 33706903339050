<template>
  <a-spin :spinning="loading">
    <div>
      <div style="min-width: 1240px">
        <a-card
          :bordered="false"
          :tabList="tabs"
          :activeTabKey="currentTab"
          @tabChange="tab => (this.currentTab = tab)"
        >
          <div v-if="!loading">
            <edit-role-details
              @onDetailsForm="form => (this.detailsForm = form)"
              v-bind:role="role"
              v-bind:visible="currentTab === 'baseDetail'"
            />
            <edit-role-actions
              @onActions="newActions => (this.updatedActions = newActions)"
              v-bind:role="role"
              v-bind:actions="actions"
              v-bind:visible="currentTab === 'actions'"
            />
          </div>
        </a-card>
        <div style="margin-top: 8px">
          <a-card :bordered="false">
            <div class="steps-action">
              <a-button @click="goBack">取消</a-button>
              <a-button type="primary" style="margin-left: 8px" @click="save">
                <a-spin :spinning="saving">保存</a-spin>
              </a-button>
            </div>
          </a-card>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ROLES,
  UPDATE_ROLE,
  GET_ROLE_ACTIONS
} from "@/store/modules/actions.type";
import Bus from "@/bus";
import EditRoleDetails from "./detail/editRoleDetails";
import EditRoleActions from "./detail/editRoleActions";
import { mergeActions } from "../../utils/actions";
import { RoleTypes } from "../../store/modules/roles";

export default {
  name: "editRole",

  components: {
    EditRoleActions,
    EditRoleDetails
  },

  created: async function() {
    this.loading = true;
    const promises = [];
    promises.push(this.getRoles(RoleTypes.USER_ROLE));
    if (this.actions.length === 0) {
      promises.push(this.getRoleActions());
    }
    await Promise.all(promises);
    this.loading = false;
    Bus.$on("getTarget", () => {
      this.$router.push({
        path: "/authorization/roles/" + this.$route.params.roleId
      });
    });
  },

  data() {
    return {
      loading: false,
      saving: false,

      detailsForm: undefined,

      updatedActions: undefined,

      tabs: [
        {
          key: "baseDetail",
          tab: "基本信息"
        },
        {
          key: "actions",
          tab: "行为信息"
        }
      ],
      currentTab: "baseDetail"
    };
  },

  computed: {
    ...mapGetters("roles", ["roles", "actions"]),

    role: function() {
      return (
        (this.roles.get(RoleTypes.USER_ROLE) &&
          this.roles
            .get(RoleTypes.USER_ROLE)
            .find(role => role.uuid === this.$route.params.roleId)) ||
        {}
      );
    }
  },

  methods: {
    ...mapActions("roles", [UPDATE_ROLE, GET_ROLES, GET_ROLE_ACTIONS]),

    save() {
      let that = this;

      const {
        detailsForm: { validateFields }
      } = this;
      validateFields(async (errors, values) => {
        if (!errors) {
          that.saving = true;
          await that.updateRole({
            ...that.role,
            ...values,
            actions: mergeActions(
              this.actions,
              this.role.actions,
              this.updatedActions
            )
          });
          that.saving = false;
          this.goBack();
        }
      });
    },

    goBack() {
      this.$router.push({ path: "/authorization/roles" });
    }
  }
};
</script>

<style scoped></style>
